// src/SmsForm.js
import React, { useState } from 'react';
import axios from 'axios';

const SmsForm = () => {
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');
  const [body, setBody] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const response = await axios.post('https://blitzlearning-server.onrender.com/send-sms', {
        to,
        from,
        body,
      });
      setMessage(response.data.message);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to send message');
    }
  };

  return (
    <div>
      <h2>Send SMS</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            To:
            <input
              type="text"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            From:
            <input
              type="text"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Body:
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              required
            ></textarea>
          </label>
        </div>
        <button type="submit">Send SMS</button>
      </form>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default SmsForm;
