import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Form, Image, Spinner, Modal } from 'react-bootstrap';
import Header from './Header';
import '../Hrms/Admin.css';
import homelogo from "../Images/Picture4.png";

function Login() {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword]= useState('');
  const [loadingForgetPassword, setLoadingForgetPassword] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://blitzlearning-server.onrender.com/adminlogin', formData);
      if (response.data.success) {
        navigate('/admindashboard/DashboardAdmin');
      } else {
        setError('Incorrect username or password');
      }
    } catch (error) {
      console.error('Error submitting form:', error);

      if (error.response && error.response.status === 401) {
        setError('Incorrect username or password');
      } else {
        setError('An error occurred while logging in');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgetPassword = async () => {
    setError('');
    setLoadingForgetPassword(true);
  
    try {
      const response = await axios.post('https://blitzlearning-server.onrender.com/forgetpassword', { email, password });
  
      if (response.data.success) {
        alert("Admin password changed the successfully")
        setShowModal(false);
      } else {
        setError('Email not found');
      }
    } catch (error) {
      console.error('Error sending password reset request:', error);
      setError('An error occurred while sending password reset request');
    } finally {
      setLoadingForgetPassword(false);
    }
  };

  return (
    <div className="employee-dashboard">
      <Header />
      <div className="employee-login">
        <Image style={{height:'80px',width:'150px'}} className="logo" src={homelogo} alt="Logo" />
        <div className="admin-container">
          <button className="Blitz-admin" type="button" onClick={() => navigate('/login')}>
            Blitz Admin
          </button>
          <form onSubmit={handleSubmit} className="blitz-form">
            <input
              type="text"
              className="form-input"
              placeholder="Username"
              required
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={{borderRadius:'10px',border:'none'}}
            />
            <input
              type="password"
              className="form-input"
              placeholder="Password"
              required
              name="password"
              value={formData.password}
              onChange={handleChange}
              style={{borderRadius:'10px',border:'none'}}
            />
            <button type="submit" className="form-submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Login'}
            </button>
          </form>
       
          <span style={{ outline: 'none', textDecoration: 'none', color:'white',marginTop:'4%' }} onClick={() => setShowModal(true)}>Reset Password</span>
          {error && <p className="error-message text-white mt-3">{error}</p>}
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group controlId="formEmail">
              <Form.Label>Admin Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your admin name"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
         
          <Button
            variant="primary"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: '#E55A1B',
              border: 'none',
              marginTop: '20px',
              width: '200px'
            }}
            onClick={handleForgetPassword}
            disabled={loadingForgetPassword}
          >
            {loadingForgetPassword ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
