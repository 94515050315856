import React from 'react'
import '../LMS/learning.css'

function Header() {
  return (
    <div className='header-txt'
   
  >
   <h1 className="headingLms " >Blitz LMS</h1>
    <p className="sub-heading " >
      Learning <br />
      Management <br />
      System
    </p>

  </div>
  )
}

export default Header