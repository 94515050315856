import React, { useState, useEffect } from "react";
import "../Dashboard/admindashboard.css";
import axios from "axios";
import Sidebar from './Sidebar';
import Navbar1 from "./Navbar1";
import calendar from "../Images/Calendar-20241.jpg";
import { Button, Container, Row, Col, Form, Image, Spinner, Card } from 'react-bootstrap';

const BlitzCalender = () => {
  const [festivals, setFestivals] = useState([]);
  const [nearestFestival, setNearestFestival] = useState(null);

  useEffect(() => {
    const fetchFestivals = async () => {
      try {
        const response = await axios.get('https://blitzlearning-server.onrender.com/api/festivals');
        const festivalsData = response.data;
        setFestivals(festivalsData);

        if (festivalsData.length > 0) {
          const today = new Date();
          const nearest = festivalsData.reduce((prev, curr) => {
            const prevDate = new Date(prev.date);
            const currDate = new Date(curr.date);
            return Math.abs(currDate - today) < Math.abs(prevDate - today) ? curr : prev;
          });
          setNearestFestival(nearest);
        }
      } catch (error) {
        console.error('Error fetching festivals:', error);
      }
    };

    fetchFestivals();
  }, []);

  return (
    <>
      <Navbar1 />
      <div className="admin-dashboard">
        <Sidebar />
        {/* <Container>
          {nearestFestival ? (
            <Card>
              <Card.Body>
                <Card.Title>Nearest Festival: {nearestFestival.name}</Card.Title>
                <Card.Text>Date: {new Date(nearestFestival.date).toDateString()}</Card.Text>
                <Card.Text>{nearestFestival.description}</Card.Text>
              </Card.Body>
            </Card>
          ) : (
            <p>Loading nearest festival...</p>
          )}
        </Container> */}
        <div className="main-content">
          <h4 className="text-center">Blitz Calendar</h4>
          <Image style={{ height: '80%', width: '100%' }} className="logo" src={calendar} alt="Logo" />
        </div>
      </div>
    </>
  );
};

export default BlitzCalender;
