import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Form, Image, Modal, Spinner } from "react-bootstrap";
import Header from './Header';
import '../Hrms/Admin.css';
import homelogo from "../Images/Picture4.png";

function Login() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false); // Add loading state for reset password

  const [resetPasswordFormData, setResetPasswordFormData] = useState({
    username: '',
    newpassword: ''
  });

  const handleResetPasswordChange = (e) => {
    setResetPasswordFormData({ ...resetPasswordFormData, [e.target.name]: e.target.value });
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setIsResetLoading(true); // Start loading
    try {
      const response = await axios.post('https://blitzlearning-server.onrender.com/resetpassword', resetPasswordFormData);
      alert('Password reset successful');
      setShowResetPassword(false);
      setError('');
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('An error occurred while resetting the password');
    }
    setIsResetLoading(false); // End loading
  };

  const [formData, setFormData] = useState({
    name: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSuccessfulLogin = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('valid', true);
    navigate('/UserDashboard', { state: { user } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post('https://blitzlearning-server.onrender.com/userlogin', formData);
      if (response.data.success) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        handleSuccessfulLogin(response.data.user);
      } else {
        setError('Incorrect username or password');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response && error.response.status === 401) {
        setError('Incorrect username or password');
      } else {
        setError('An error occurred while logging in');
      }
    }
    setIsLoading(false); // End loading
  };

  return (
    <div className='employee-dashboard'>
      <Header />
      <div className="employee-login">
        <Image style={{ height: '80px', width: '150px' }} className="logo" src={homelogo} alt="Logo" />
        <div className="admin-container">
          <button className='Blitz-admin' type="submit" onClick={() => navigate("/login")}>Blitz User</button>
          <form onSubmit={handleSubmit} className="blitz-form">
            <input
              type="text"
              className='form-input'
              placeholder="Username"
              required
              name='name'
              value={formData.name}
              onChange={handleChange}
              style={{ borderRadius: '10px', border: 'none' }}
            />
            <input
              type="password"
              className='form-input'
              placeholder="Password"
              required
              name='password'
              value={formData.password}
              onChange={handleChange}
              style={{ borderRadius: '10px', border: 'none' }}
            />
            <button type="submit" className='form-submit' disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Login'}
            </button>
          </form>
          <span style={{ outline: 'none', textDecoration: 'none' }} className='txt' onClick={() => setShowResetPassword(true)}>Forget your password?</span>
          {error && <p className="error-message text-white mt-3">{error}</p>}
        </div>
      </div>
      {/* Reset Password Modal */}
      <Modal show={showResetPassword} onHide={() => setShowResetPassword(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleResetPasswordSubmit}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your username"
                name="username"
                value={resetPasswordFormData.username}
                onChange={handleResetPasswordChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                name="newpassword"
                value={resetPasswordFormData.newpassword}
                onChange={handleResetPasswordChange}
                required
              />
            </Form.Group>
            <Button variant="primary my-3" type="submit" style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: '#E55A1B',
              border: 'none',
              marginTop: '20px',
              width: '200px' // Adjust the width as needed
            }} disabled={isResetLoading}>
              {isResetLoading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
