import React, { Fragment, useState, useEffect } from 'react'; // Import useEffect

function App() {

  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch blob image from API
    fetch('https://blitzlearning-server.onrender.com/images') // Replace with your API endpoint
        .then(response => response.blob())
        .then(blob => {
            // Convert blob to URL
            const imageUrl = URL.createObjectURL(blob);
            // Set the image URL state
            setImageUrl(imageUrl);
        })
        .catch(error => console.error('Error fetching blob image:', error));

    // Clean up: Revoke the object URL to free up resources
    return () => {
        if (imageUrl) {
            URL.revokeObjectURL(imageUrl);
        }
    };
  }, []); // Runs once on component mount
  

  const selectedHandler = e => {
    setFile(e.target.files[0]);
  };

  const sendHandler = () => {
    if (!file) {
      alert('you must upload file');
      return;
    }

    const formdata = new FormData();
    formdata.append('image', file);

    // Append image URL to form data
    formdata.append('imageUrl', imageUrl);
// console.log(imageUrl);
    fetch('https://blitzlearning-server.onrender.com/images/post', {
      method: 'POST',
      body: formdata
    })
    .then(res => res.text())
    .then(res => console.log(res))
    .catch(err => {
      console.error(err);
    });

    document.getElementById('fileinput').value = null;

    setFile(null);
  };

  return (
    <Fragment>
      <nav className="navbar navbar-dark bg-dark">
        <div className="container">
          <a href="#!" className="navbar-brand">Image App</a>
        </div>
      </nav>

      <div className="container mt-5">
        <div className="card p-3">
          <div className="row">
            <div className="col-10">
              <input id="fileinput" onChange={selectedHandler} className="form-control" type="file"/>
            </div>
            <div className="col-2">
              <button onClick={sendHandler} type="button" className="btn btn-primary col-12">Upload</button>
            </div>
            {imageUrl && <img src={imageUrl} alt="Blob Image" />}
     
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default App;

