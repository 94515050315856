import React from 'react'
import '../Accounts/Accounts.css'

function HeaderAccounts() {
  return (
    <div className='header-txt' >
   <h1 className="Accounts-heading " >Blitz Accounts</h1>
   

  </div>
  )
}

export default HeaderAccounts