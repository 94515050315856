import React from "react";
import { Link } from "react-router-dom";

const UserSidebar = ({ user }) => {

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  let base64String = "";
  if (user && user.data && user.data.data) {
    base64String = arrayBufferToBase64(user.data.data);
  }

  return (
    <div
      className="col-auto col-md-3 col-xl-2 px-sm-2 px-4 bg-black"
      style={{ backgroundColor: "#E55A1B" }}
    >
      <div className="sidebar">
        {user && (
          <div
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
              marginRight: "80px",
            }}
          >
            {base64String && (
              <img
                className="pro"
                src={`data:image/png;base64,${base64String}`}
                alt="User Profile"
                style={{ marginRight: "15px" }}
              />
            )}
            <h2 style={{ color: "white", fontSize: "15px", marginTop: "9%" }}>
              {user.name}
            </h2>
          </div>
        )}
      </div>
      <div className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-0 text-white min-vh-100">
        <Link
          to="/"
          className="d-flex align-items-center pb-3 mb-md-1 mt-md-0 me-md-auto text-white text-decoration-none"
        >
          <span className="fs-5 fw-bolder d-none d-sm-inline"></span>
        </Link>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
          id="menu"
        >
          <li>
            <Link
              to="/UserDashboard"
              className="nav-link text-white px-0 align-middle"
            >
              <i className="fs-5 bi-house-door"></i>
              <span className="ms-2 d-none d-sm-inline">User Dashboard</span>
            </Link>
          </li>
          <li>
            <Link
              to="/UserDashboard/LeaveForm"
              className="nav-link text-white px-0 align-middle"
            >
              <i className="fs-5 bi-file-earmark-text"></i>
              <span className="ms-2 d-none d-sm-inline">Leave Form Request</span>
            </Link>
          </li>
          <li>
            <Link
              to="/UserDashboard/LeaveDetails"
              className="nav-link text-white px-0 align-middle"
            >
              <i className="fs-5 bi-calendar3"></i>
              <span className="ms-2 d-none d-sm-inline">User Leave details</span>
            </Link>
          </li>
          {/* UserDashboard/usertimein */}

          <li>
            <Link
              to="/UserDashboard/usertimein"
              className="nav-link text-white px-0 align-middle"
            >
             <i className="fs-5 bi-clock"></i>

              <span className="ms-2 d-none d-sm-inline">User Timein details</span>
            </Link>
          </li>
          <li>
                        <Link to="/UserDashboard/userblitzcalender" className="nav-link text-white px-0 align-middle">
                            <i className="fs-5 bi-calendar"></i> <span className="ms-1 d-none d-sm-inline">Blitz Calendar</span>
                        </Link>
                    </li>
          <li>
            <a
              href="/userlogin"
              className="nav-link text-white px-0 align-middle"
            >
              <i className="fs-5 bi-power"></i>
              <span className="ms-2 d-none d-sm-inline">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserSidebar;
