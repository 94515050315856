import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Form } from "react-bootstrap";
import Navbar1 from "./Navbar1";
import UserSidebar from "./UserSidebar";
import axios from "axios";
import "../Dashboard/admindashboard.css";

function LeaveForm() {
  const [users, setUser] = useState(null);
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentLeave, setCurrentLeave] = useState(null);
  const [leavetype, setLeaveType] = useState('');
  const [reason, setReason] = useState(''); // Step 1: Reason state

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));

      const employeeCode = JSON.parse(storedUser).emp_code;
      if (employeeCode) {
        fetchLeaveData(employeeCode);
      }
    }
  }, []);

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const fetchLeaveData = async (employeeCode) => {
    try {
      const url = `https://blitzlearning-server.onrender.com/leavedetails/${employeeCode}`;
      const response = await axios.get(url);
      setLeaveData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching leave data:", error);
      setLoading(false);
    }
  };

  const incrementDateByOneDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate());   // + 1
    return date.toISOString();
  };
  

  const handleDelete = async (leave) => {
    // Increment dates by one day
    const updatedLeave = {
      ...leave,
      applied_leave_dates: incrementDateByOneDay(leave.applied_leave_dates),
      enddate: incrementDateByOneDay(leave.enddate),
      startdate: incrementDateByOneDay(leave.startdate),
    };
  
    const { data, ...cleanedLeave } = updatedLeave;
    if (!window.confirm('Do you want to delete the leave application?')) {
      return;
    }
  
    try {
      const url = `https://blitzlearning-server.onrender.com/leavedetails/delete/${cleanedLeave.name}`;
      await axios.delete(url, { data: cleanedLeave });
      alert('Leave detail deleted successfully');
      fetchLeaveData(users.emp_code); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting leave data:", error);
      alert('Failed to delete leave detail. Please try again later.');
    }
  };
  


  const handleEdit = (leave) => {
    setCurrentLeave(leave);
    setLeaveType(leave.leavetype);
    setReason(leave.reason); // Step 2: Set reason from leave
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentLeave({ ...currentLeave, [name]: value });
    if (name === 'reason') {
      setReason(value); // Update reason state
    }
  };

  const handleSaveChanges = async () => {
    const updatedDate = new Date(currentLeave.applied_leave_dates);
    updatedDate.setDate(updatedDate.getDate()); // Increment the date by 1 day
    const formattedDate = updatedDate.toISOString();

    const updatedLeaveData = {
      name: currentLeave.name,
      leavetype: leavetype,
      startdate: currentLeave.startdate,
      enddate: currentLeave.enddate,
      daysofleave: currentLeave.daysofleave,
      reason: reason, // Use reason state
      status: currentLeave.status,
      applied_leave_dates: formattedDate, // Use the modified date here
      emp_code: currentLeave.emp_code,
      total_leaves: currentLeave.total_leaves,
      email: currentLeave.email
    };
  
    try {
      // Perform the PUT request with updatedLeaveData
      await axios.put(`https://blitzlearning-server.onrender.com/leavedetails/${currentLeave.id}`, updatedLeaveData);
      
      // Close modal after successful update
      setShowModal(false);
  
      // Refresh data after update
      fetchLeaveData(users.emp_code);
    } catch (error) {
      console.error("Error updating leave data:", error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const isStartDateInPast = (startDate) => {
    const today = new Date();
    const start = new Date(startDate);
    return start <= today;
  };
  return (
    <>
      <Navbar1 />
      <div className="admin-dashboard">
        <UserSidebar user={users} />
        <div className="main-content">
          <h2 className="text-center">User Profiles</h2>
          <Table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Emp Code</th>
                <th>Profile</th>
                <th>Name</th>
                <th>Applied Dates</th>
                <th>Leave Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Days of leave</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="12">Loading...</td>
                </tr>
              ) : leaveData.length === 0 ? (
                <tr>
                  <td colSpan="12">No leave requests found.</td>
                </tr>
              ) : (
                leaveData.map((leave, index) => (
                  <tr key={index}>
                    <td>{leave.emp_code}</td>
                    <td>
                      <div className="profile-avatar">
                        {leave &&
                          leave.data &&
                          (() => {
                            const base64String = arrayBufferToBase64(
                              leave.data.data
                            );
                            return (
                              <img
                                className="pro"
                                src={`data:image/png;base64,${base64String}`}
                              />
                            );
                          })()}
                      </div>
                    </td>
                    <td>{leave.name}</td>
                    <td>{formatDate(leave.applied_leave_dates)}</td>
                    <td>{leave.leavetype}</td>
                    <td>{formatDate(leave.startdate)}</td>
                    <td>{formatDate(leave.enddate)}</td>
                    <td>{leave.daysofleave}</td>
                    <td className="status">
                      {leave.status === "Pending" && (
                        <span className="pending">{leave.status}</span>
                      )}
                      {leave.status === "Approved" && (
                        <span className="approved">{leave.status}</span>
                      )}
                      {leave.status === "Rejected" && (
                        <span className="decline">{leave.status}</span>
                      )}
                    </td>
                    <td>
                      <Button
                        variant="success"
                        onClick={() => handleEdit(leave)}
                        disabled={isStartDateInPast(leave.startdate)}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{ marginLeft: '5px' }}
                        variant="danger"
                        onClick={() => handleDelete(leave)}
                        disabled={isStartDateInPast(leave.startdate)}
                      >
                        Cancel
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {currentLeave && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Leave Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="leaveType">
                <Form.Label>Leave Type:</Form.Label>
                <Form.Select
                  value={leavetype}
                  name="leavetype"
                  onChange={(e) => {
                    setLeaveType(e.target.value);
                    handleInputChange(e);
                  }}
                  required
                >
                  <option value="">Select Leave Type</option>
                  <option value="Vacation Leave">Vacation Leave</option>
                  <option value="Sick Leave">Sick Leave</option>
                  <option value="Maternity/Paternity Leave">Maternity/Paternity Leave</option>
                  <option value="Study Leave">Study Leave</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="formStartDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startdate"
                  value={currentLeave.startdate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formEndDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="enddate"
                  value={currentLeave.enddate}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formDaysOfLeave">
                <Form.Label>Days of Leave</Form.Label>
                <Form.Control
                  type="number"
                  name="daysofleave"
                  value={currentLeave.daysofleave}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formReason"> {/* Step 3: Add Reason input */}
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="reason"
                  value={reason}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button> */}
            <Button style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: '#E55A1B',
                                border: 'none',
                                marginTop: '10px',
                                width: '200px' // Adjust the width as needed
                            }} 
                            variant="primary" 
                            onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default LeaveForm;
