import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar1 from "./Navbar1";
import UserSidebar from "./UserSidebar";
import calendar from "../Images/Calendar-20241.jpg";
import { Button, Container, Row, Col, Form, Image, Spinner, Card } from 'react-bootstrap';
function UserBlitzCalender() {
  const [email, setEmail] = useState(""); // New state for email 
  const [users, setUser] = useState(null);
  const [empCode, setEmpCode] = useState();
  const [depart, setDepart] = useState([]);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setEmpCode(parsedUser.emp_code);
      setEmail(parsedUser.email); // Set email from stored user
      setDepart(parsedUser.department);
    }

    const employeeCode = JSON.parse(storedUser).emp_code;
    if (employeeCode) {
     
    }
    
  }, []);

 
  return (
    <>
      <Navbar1 />
      <div style={{ display: "flex", backgroundColor: "#aecfdb" }}>
        <UserSidebar user={users} />
        <div className="leave-applications">
          <div className="p-0 d-flex justify-content-center shadow">
            <h3>Blitz Calendar</h3>
          </div>
          <Container className="px-3 mt-3">
          <Image style={{ height: '70%', width: '100%' }} className="logo" src={calendar} alt="Logo" />
          </Container>
        </div>
      </div>
    </>
  );
}

export default UserBlitzCalender;
