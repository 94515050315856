import React, { useState } from 'react';
import { Button, Modal, Form, Image } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import homelogo from "../Images/Picture4.png";
import '../Hrms/employeeLogin.css';

function EmployeeLogin() {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    emp_code:'',
    username: '',
    password: '',
    confirmPassword: '',
    dob:'',
    name: '',
    dateOfJoining: '',
    designation: '',
    department: ''
  });
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectedHandler = e => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!file) {
        alert('Please upload an image');
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('image', file);
      Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await axios.post('https://blitzlearning-server.onrender.com/upload-and-save-employee', formDataToSend);

      // Show alert message
      showAlert();

      // Clear form data and file state after successful submission
      setFormData({
        emp_code:'',
        username: '',
        password: '',
        confirmPassword: '',
        dob:'',
        name: '',
        dateOfJoining: '',
        designation: '',
        department: ''
      });
      setFile(null);
    } catch (error) {
      console.error('Error saving employee details:', error);
      // Handle error
    }
  };

  const showAlert = () => {
    window.alert('Form submitted successfully');

    // Close the alert after 3 seconds
    setTimeout(() => {
      const alertBox = document.querySelector('.alert');
      if (alertBox) {
        alertBox.style.display = 'none';
      }
      // Redirect to previous page
      window.history.back();
    }, 3000);
  };

  return (
    <div className='employee-dashboard'>
      <Header />
      <div className="employee-login">
        <Image style={{height:'80px',width:'150px'}} className="logo" src={homelogo} alt="Logo" />
        <div className='content'>
          <Button className='Admin-btn' variant="primary" size="lg" onClick={() => navigate("/adminlogin")}>
            Admin Login
          </Button>
          <Button className='User-btn' variant="primary" size="lg" onClick={() => navigate("/userlogin")}>
            User Login
          </Button>
          <br />
          <br />
          <p className='paragraph-text'>
            Choose your role to login using your credentials. <br/>If you don't have your login credentials, sign up below.
          </p>
          <Button className='Newuser-btn' variant="primary" size="lg" onClick={() => setShowModal(true)}>
            New user sign up
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>New User Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmpCode">
              <Form.Label>Employee Code</Form.Label>
              <Form.Control type="text" placeholder="Employee code" required name="emp_code" value={formData.emp_code} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicFile">
              <Form.Label>Upload Picture</Form.Label>
              <Form.Control type="file" required name="upl_pic" onChange={selectedHandler} />
            </Form.Group>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Username" required name="username" value={formData.username} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" required name="password" value={formData.password} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" placeholder="Confirm Password" required name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicDOB">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control type="date" placeholder="Date of Birth" required name="dob" value={formData.dob} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Name" required name="name" value={formData.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicDateOfJoining">
              <Form.Label>Date of Joining</Form.Label>
              <Form.Control type="date" placeholder="Date of Joining" required name="dateOfJoining" value={formData.dateOfJoining} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Control type="text" placeholder="Designation" required name="designation" value={formData.designation} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formBasicDepartment">
              <Form.Label>Department</Form.Label>
              <Form.Control as="select" required name="department" value={formData.department} onChange={handleChange}>
                <option value="" disabled>Select Department</option>
                <option value="Sales">Sales</option>
                <option value="Production">Production</option>
                <option value="Support">Support</option>
                <option value="Accounts">Accounts</option>
                <option value="IT">IT</option>
                <option value="Operation">Operation</option>
              </Form.Control>
            </Form.Group>
            <Button className='mt-3 ml-4' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#E55A1B',border:'none' }} variant="primary" type="submit">
              Sign Up
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EmployeeLogin;
