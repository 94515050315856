import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Container, Row, Col, Card, Table, Button } from "react-bootstrap";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link, useNavigate } from "react-router-dom";
import "../Dashboard/dashboard-admin.css";
import Navbar1 from "./Navbar1";
import Sidebar from "./Sidebar";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Dashboard/dashboard-admin.css";
import axios from "axios";
import festivalData from './festivalData';
const DashboardAdmin = () => {
  const percentage = 66;
  const date = new Date();
  const [leaveData, setLeaveData] = useState([]);

  const [approvedData, setApprovedData] = useState([]);

  const [rejectedData, setRejectedData] = useState([]);
  const [averageTimeIn, setAverageTimeIn] = useState('');
  const [averageWorkTime, setAverageWorkTime] = useState('');
  const [averageTimeOut, setAverageTimeOut] = useState('');
  useEffect(() => {
    fetchLeaveData();
    fetchApprovedData();
    fetchRejectedData();
    fetchTrackingLeaves();
    fetchAverageTimeIn();
    fetchAverageWorkTime();
    fetchAverageTimeOut();
  }, []);
  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  const fetchAverageTimeIn = async () => {
    try {
      // Replace 'https://blitzlearning-server.onrender.com' with your actual backend URL
      const response = await axios.get('https://blitzlearning-server.onrender.com/getAverageTimeIn');
      const data = response.data;

      // Assuming your backend response is { average_time_in: 'HH:MI AM/PM' }
      setAverageTimeIn(data.average_time_in);
    } catch (error) {
      console.error('Error fetching average time in:', error);
      // Handle error state if needed
    }
  };

  const fetchAverageWorkTime = async () => {
    try {
      const response = await axios.get('https://blitzlearning-server.onrender.com/getAverageWorkTime');
      const data = response.data;

      // Assuming your backend response is { overallAverageWorkTime: '5h 45m' }
      setAverageWorkTime(data.overallAverageWorkTime);
    } catch (error) {
      console.error('Error fetching average work time:', error);
      // Handle error state if needed
    }
  };

  const fetchAverageTimeOut = async () => {
    try {
      const response = await axios.get('https://blitzlearning-server.onrender.com/getAverageTimeOut');
      const data = response.data;

      // Assuming your backend response is { average_time_out: '6:30 PM' }
      setAverageTimeOut(data.average_time_out);
    } catch (error) {
      console.error('Error fetching average time out:', error);
      // Handle error state if needed
    }
  };

  const fetchLeaveData = async () => {
    try {
      const response = await axios.get("https://blitzlearning-server.onrender.com/api/leave-data");
      setLeaveData(response.data);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const fetchRejectedData = async () => {
    try {
      const response = await axios.get(
        "https://blitzlearning-server.onrender.com/api/Rejected-data"
      );
      setRejectedData(response.data);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const fetchApprovedData = async () => {
    try {
      const response = await axios.get(
        "https://blitzlearning-server.onrender.com/api/approved-data"
      );
      setApprovedData(response.data);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const [trackingData, setTrackingData] = useState([]);

  const fetchTrackingLeaves = async () => {
    try {
      const response = await axios.get(
        "https://blitzlearning-server.onrender.com/api/tracking-leaves1"
      );
      setTrackingData(response.data);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
 // Get the current month and day
 const currentDate = new Date();
 const currentMonth = currentDate.toLocaleString("default", { month: "short" });

 const filteredFestivals = festivalData.filter(
   (festival) => festival.id === currentMonth
 );

//  const filteredFestivals = festivalData.filter((festival) => {
//   // Parse festival date and compare with current date
//   const festivalDate = new Date(festival.date); // Assuming festival.date is in a format that Date() can parse
//   // Calculate difference in milliseconds between current date and festival date
//   const timeDifference = festivalDate.getTime() - currentDate.getTime();
//   // Convert milliseconds to days
//   const daysDifference = timeDifference / (1000 * 3600 * 24);

//   // Filter festivals that are within a certain number of days from the current date
//   return daysDifference >= 0 && daysDifference <= 30; // Adjust the number of days as needed
// });
return (
  <>
    <Navbar1 />
    <div className="admin-dashboard">
      <Sidebar />
      <div className="main-content">
        <div className="row p-1">
        
        {filteredFestivals.length > 0 ? (
  <div className="basic-column w-col w-col-3">
    <div className="tag-wrapper">
      <div
        className="number-card number-card-content1"
        style={{ background: 'linear-gradient(to right, #ff0099, #493240)' }} // You can choose a color or pick one dynamically if needed
      >
        <h2 className="number-card-number2">Your Holidays</h2>
        <div className="number-card-divider"></div>
        {filteredFestivals.map((festival) => (
          <div key={festival.name} className="number-card-dollars33">
            {festival.name} - <span className="number-card-progress33">{festival.date}</span>
          </div>
        ))}
      </div>
      <div className="divider"></div>
    </div>
  </div>
) : (
  <div className="basic-column w-col w-col-3">
  <div className="tag-wrapper1">
    <div
      className="number-card number-card-content1"
      style={{ background:  'linear-gradient(to right, #02aab0, #00cdac)'}}
     
    >
      <h2 className="number-card-number2">No holiday in this month
      </h2>
      <div className="number-card-divider"></div>
      <div className="number-card-dollars1"></div>
      <div className="number-card-progress-wrapper">
        <div className="tagline number-card-currency">
          <i className="fs-5 bi-calendar2"></i>
        </div>
        <div className="number-card-progress"></div>{" "}
        {/* Display the date */}
      </div>
    </div>
    <div className="divider"></div>
  </div>
</div>
)}

       
    
  

            <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper1">
                <div class="number-card number-card-content2">
                  <h2 class="number-card-number1">{averageTimeIn}</h2>
                  {/* {vacationLeaveData.map((item, index) => ( */}
                  <div class="number-card-dollars1">
                    Average in time of team
                  </div>
                  {/* ))} */}
                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper"></div>
                </div>
                <div class="divider"></div>
              </div>
            </div>
            <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper1">
                <div class="number-card number-card-content3">
                  <h2 class="number-card-number1">{averageWorkTime}</h2>
                  {/* {sickLeaveData.map((item, index) => ( */}
                  <div class="number-card-dollars1">Team Average work time</div>
                  {/* ))} */}
                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper">
                    {/* <div class="tagline number-card-currency">BTC</div>
                    <div class="number-card-progress">+932.1%</div> */}
                  </div>
                </div>
                <div class="divider"></div>
                {/* <div class="style-label">.NUMBER-CARD .NUMBER-CARD-CONTENT3</div> */}
              </div>
            </div>
            <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper1">
                <div class="number-card number-card-content4">
                  <h2 class="number-card-number1">{averageTimeOut}</h2>
                  <div class="number-card-dollars1">
                    Average out time of teams
                  </div>

                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper">
                    {/* <div class="tagline number-card-currency">ENG</div>
                    <div class="number-card-progress">+3.2%</div> */}
                  </div>
                </div>
                <div class="divider"></div>
                {/* <div class="style-label">.NUMBER-CARD .NUMBER-CARD-CONTENT4</div> */}
              </div>
            </div>
          </div>

          <div className="row a1">
            <div class="col-md-6">
              <div class="dashboard-card-3">
                <h2 className="text-center">Approved Leaves</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Emp Code</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Applied Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedData.map((leave) => (
                      <tr key={leave.id}>
                        <td>{leave.emp_code}</td>
                        <td>{leave.name}</td>

                        <td class="status">
                          <span class="approved">{leave.status}</span>
                        </td>

                        <td>{formatDate(leave.applied_leave_dates)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-md-6">
              <div class="dashboard-card-3">
                <h2 className="text-center">Declined Leaves</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Emp Code</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Applied Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rejectedData.map((leave) => (
                      <tr key={leave.id}>
                        <td>{leave.emp_code}</td>
                        <td>{leave.name}</td>

                        <td class="status">
                          <span class="decline">{leave.status}</span>
                        </td>
                        <td>{formatDate(leave.applied_leave_dates)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div class="dashboard-card-3">
                <h2 className="text-center">Pending Leaves</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Emp Code</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Applied Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {leaveData.map((leave) => (
                      <tr key={leave.id}>
                        <td>{leave.emp_code}</td>
                        <td>{leave.name}</td>

                        <td class="status">
                          <span class="pending">{leave.status}</span>
                        </td>

                        <td>{formatDate(leave.applied_leave_dates)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-0 p-2">
            <div class="col-md-12">
              <div class="dashboard-card-3">
                <h2 className="text-center">Leaves Tracking</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Emp Code</th>
                      <th>Profile</th>
                      <th>Name</th>
                      <th>Applied Date</th>
                      <th>Applied Leaves Days</th>
                      <th>Leaves Taken</th>
                      <th>Remaining Leaves</th>
                      <th>Leave Meter</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trackingData.map((leave) => (
                      <tr key={leave.employeeCode}>
                        <td className="text-center">{leave.emp_code}</td>

                        <td className="text-center">
                          <div className="profile-avatar-1">
                            {leave &&
                              leave.data &&
                              (() => {
                                const base64String = arrayBufferToBase64(
                                  leave.data.data
                                );
                                return (
                                  <img
                                    className="pro-1"
                                    src={`data:image/png;base64,${base64String}`}
                                  />
                                );
                              })()}
                          </div>
                        </td>

                        <td className="text-center">{leave.name}</td>
                        <td className="text-center">{formatDate(leave.applied_leave_dates)}</td>
                        <td className="text-center">{leave.daysofleave}</td>
                        <td className="text-center">{leave.taking_leaves}</td>

                        <td className="text-center">
                          {leave.total_leaves - leave.taking_leaves}/{leave.total_leaves}
                        </td>
                        <td>
                          <ProgressBar
                            completed={(
                              (leave.taking_leaves / leave.total_leaves) *
                              100
                            ).toFixed(1)} // Limits to one decimal place
                            bgColor={
                              leave.total_leaves - leave.taking_leaves > 15
                                ? "#2ecc71"
                                : leave.total_leaves - leave.taking_leaves > 10
                                ? "#f1c40f"
                                : "#e74c3c"
                            }
                            labelAlignment="outside"
                            labelColor="#060708"
                            height={10}
                            customLabel={`${(
                              (leave.taking_leaves / leave.total_leaves) *
                              100
                            ).toFixed(1)}%`} // Adding the percentage label
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAdmin;
