import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import '../Dashboard/admindashboard.css';
import Navbar1 from './Navbar1';
import Sidebar from './Sidebar';

function AllUsers() {
  const [userProfiles, setUserProfiles] = useState([]);
  const [editedUser, setEditedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [adminName, setAdminName] = useState('');
  const [showModal, setShowModal] = useState(false); // State variable to control modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State variable to control delete modal visibility
  const [deletingUserId, setDeletingUserId] = useState(null); // State variable to store user ID being deleted
  const [isLoading, setIsLoading] = useState(false); // Loading state for fetching user profiles
  const [isSaving, setIsSaving] = useState(false); // Loading state for saving profile
  const [isDeleting, setIsDeleting] = useState(false); // Loading state for deleting profile

  useEffect(() => {
    fetchUserProfiles();
    fetchAdminName();
  }, []);

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const fetchUserProfiles = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://blitzlearning-server.onrender.com/userProfiles');
      const sortedProfiles = response.data.sort((a, b) => a.emp_code - b.emp_code);
      setUserProfiles(sortedProfiles);
    } catch (error) {
      console.error('Error fetching user profiles:', error);
    }
    setIsLoading(false);
  };

  const fetchAdminName = async () => {
    try {
      const response = await axios.get('https://blitzlearning-server.onrender.com/admin/profile');
      setAdminName(response.data.name);
    } catch (error) {
      console.error('Error fetching admin name:', error);
    }
  };

  const handleEdit = (emp_code) => {
    const profileToEdit = userProfiles.find(profile => profile.emp_code === emp_code);
    setEditedUser(profileToEdit);
    setIsEditing(true);
    setShowModal(true); // Open the modal when editing starts
  };

  const handleCloseModal = () => {
    setIsEditing(false);
    setShowModal(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const userId = editedUser.emp_code;
      const { data, ...filteredUser } = editedUser;
      await axios.put(`https://blitzlearning-server.onrender.com/userProfiles/${userId}`, filteredUser);
      fetchUserProfiles();
      setEditedUser(null);
      setIsEditing(false);
      setShowModal(false);
    } catch (error) {
      console.error('Error saving user profile:', error);
    }
    setIsSaving(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDelete = async (emp_code) => {
    setIsDeleting(true);
    try {
      const numericPart = emp_code.replace("Blitz", "");
      const userId = parseInt(numericPart);
      await axios.delete(`https://blitzlearning-server.onrender.com/userProfiles/${userId}`);
      fetchUserProfiles();
      setShowDeleteModal(false); // Close the delete confirmation modal
    } catch (error) {
      console.error('Error deleting user profile:', error);
    }
    setIsDeleting(false);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <Navbar1 />
      <div className="admin-dashboard">
        <Sidebar adminName={adminName} />
        <div className="main-content">
          <h2 className="text-center">User Profiles</h2>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <table className="table table-bordered table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Emp Code</th>
                  <th>Profile</th>
                  <th>Name</th>
                  <th>User Name</th>
                  <th>Date of Birth</th>
                  <th>Date of Joining</th>
                  <th>Designation</th>
                  <th>Department</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {userProfiles.map((profile, index) => (
                  <tr key={profile.emp_code}>
                    <td>{profile.emp_code}</td>
                    <td className="text-center">
                      <div className="profile-avatar">
                        {profile && profile.data && (() => {
                          const base64String = arrayBufferToBase64(profile.data.data);
                          return <img className='pro' key={index} src={`data:image/png;base64,${base64String}`} alt={`Image ${index}`} />;
                        })()}
                      </div>
                    </td>
                    <td>{profile.name}</td>
                    <td>{profile.username}</td>
                    <td>{formatDate(profile.dob)}</td>
                    <td>{formatDate(profile.dateofjoining)}</td>
                    <td>{profile.designation}</td>
                    <td>{profile.department}</td>
                    <td>
                      <Button variant="warning" size="sm" onClick={() => handleEdit(profile.emp_code)}>Edit Profile</Button>
                      <Button className='pr-5' style={{ marginLeft: '10px', marginRight: 'auto' }} variant="danger" size="sm" onClick={() => {
                        setShowDeleteModal(true);
                        setDeletingUserId(profile.emp_code);
                      }}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {/* Modal for editing user profile */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Input fields for editing user profile */}
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={editedUser?.name || ''} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="formDOB">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                name="dob"
                value={editedUser?.dob ? editedUser.dob : ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDateOfJoining">
              <Form.Label>Date of Joining</Form.Label>
              <Form.Control
                type="date"
                name="dateofjoining"
                value={editedUser?.dateofjoining ? editedUser.dateofjoining : ''}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Control type="text" name="designation" value={editedUser?.designation || ''} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="formDepartment">
              <Form.Label>Department</Form.Label>
              <Form.Control type="text" name="department" value={editedUser?.department || ''} onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#E55A1B',
            border: 'none',
            marginTop: '20px',
            width: '200px' // Adjust the width as needed
          }} variant="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirming deletion */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this user profile?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => {
            handleDelete(deletingUserId);
          }} disabled={isDeleting}>
            {isDeleting ? <Spinner animation="border" size="sm" /> : 'Delete'}
          </Button>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllUsers;
