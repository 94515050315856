import React, { useState, useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import axios from "axios";
import "../Dashboard/userdashboard.css";

import Navbar1 from "./Navbar1";
import UserSidebar from "./UserSidebar";
import { Button, ButtonGroup, Card, Spinner } from "react-bootstrap";
import { BsForward } from "react-icons/bs";
import { FaFolderOpen } from "react-icons/fa6";
import { IoFastFoodOutline } from "react-icons/io5";
import { PiBowlSteamFill } from "react-icons/pi";
import { LiaSmokingSolid } from "react-icons/lia";
import "react-calendar/dist/Calendar.css";
import festivalData from './festivalData';
function UserDashboard() {
  // const location = useLocation();
  const [user, setUser] = useState(null);
  const [timesheetData, setTimesheetData] = useState([]);
  const [vacationLeaveData, setVacationLeaveData] = useState([]);
  const [sickLeaveData, setSickLeaveData] = useState([]);
  const [materinityLeaveData, setMaterinityLeaveData] = useState([]);
  const todayDate = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD
  const [trackingData, setTrackingData] = useState([]);
  const [totalleave, setTotalLeave] = useState([]);
  const [takingLeave, setTakingLeave] = useState([]);
  const [timeCount, setTimeCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leaveData, setLeaveData] = useState([]);
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    fetchVacationLeaveData();
    fetchSickLeaveData();
    fetchMaterinityLeaveData();
    fetchTrackingLeaves();
    fetchTimeCount();
    fetchTimesheetAndLeaveData();
  }, []);

  const fetchTimeCount = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const employeeCode = JSON.parse(storedUser).emp_code;
      const response = await axios.get(
        `https://blitzlearning-server.onrender.com/userTimeCount?employeeCode=${employeeCode}`
      );
      setTimeCount(response.data);
    } catch (error) {
      console.error("Error fetching sick leave data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTimesheetAndLeaveData = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const employeeCode = JSON.parse(storedUser).emp_code;
      const employeeUsername = JSON.parse(storedUser).username;

      const url = `https://blitzlearning-server.onrender.com/timesheet22?employeeCode=${employeeCode}&employeeUsername=${employeeUsername}`;
      const response = await axios.get(url);
      setTimesheetData(response.data.timeSheet);
      setLeaveData(response.data.leaves);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchVacationLeaveData = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const employeeCode = JSON.parse(storedUser).emp_code;
      const employeeUsername = JSON.parse(storedUser).username;
      const response = await axios.get(
        `https://blitzlearning-server.onrender.com/vacation-leave?employeeCode=${employeeCode}&employeeUsername=${employeeUsername}`
      );
      setVacationLeaveData(response.data);
    } catch (error) {
      console.error("Error fetching vacation leave data:", error);
    }
  };

  const fetchSickLeaveData = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const employeeCode = JSON.parse(storedUser).emp_code;
      const employeeUsername = JSON.parse(storedUser).username;
      const response = await axios.get(
        `https://blitzlearning-server.onrender.com/sick-leave?employeeCode=${employeeCode}&employeeUsername=${employeeUsername}`
      );
      setSickLeaveData(response.data);
    } catch (error) {
      console.error("Error fetching sick leave data:", error);
    }
  };

  const fetchMaterinityLeaveData = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const employeeCode = JSON.parse(storedUser).emp_code;
      const employeeUsername = JSON.parse(storedUser).username;
      const response = await axios.get(
        `https://blitzlearning-server.onrender.com/materinity-leave?employeeCode=${employeeCode}&employeeUsername=${employeeUsername}`
      );
      setMaterinityLeaveData(response.data);
    } catch (error) {
      console.error("Error fetching Materinity leave data:", error);
    }
  };

  const fetchTrackingLeaves = async () => {
    try {
      const storedUser = localStorage.getItem("user");
      if (!storedUser) {
        throw new Error("No user found in localStorage");
      }
  
      const employeeCode = JSON.parse(storedUser).emp_code;
      const response = await axios.get(
        `https://blitzlearning-server.onrender.com/api/tracking-leaves?employeeCode=${employeeCode}`
      );
  
      const leaveData = response.data[0];
      
      if (leaveData) {
        setTotalLeave(leaveData.total_leaves);
        setTakingLeave(leaveData.taking_leaves); // Adjust the property names according to your data
        setTrackingData(leaveData.balance_leave); // Adjust the property names according to your data
      } else {
        console.log("No leave data found for the employee.");
      }
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };

  const handleTimeInClick = async () => {
    const now = new Date();
    const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    const morningLimit = 10 * 3600 + 15 * 60; // 10:15:00 in seconds
    const afternoonStart = 13 * 3600; // 13:00:00 in seconds
    const afternoonEnd = 14 * 3600 + 30 * 60; // 14:30:00 in seconds

    // Check for leave conditions
    const leave = leaveData[0];
    if (leave) {
      if (leave.leave_duration === "FirstHalf" && (currentTime < afternoonStart || currentTime > afternoonEnd)) {
        alert("For FirstHalf leave, you can Time In only between 1:00 PM to 2:30 PM.");
        return;
      } else if (leave.leave_duration === "SecondHalf" && currentTime > morningLimit) {
        alert("For SecondHalf leave, you cannot Time In after 10:15 AM.");
        return;
      }
    } else if (currentTime > morningLimit) {
      alert("The current time is after 10:15:00. You cannot Time In.");
      return;
    }


    // if (leave) {
    //   if (leave.leave_duration === "SecondHalf" && (currentTime < afternoonStart || currentTime > afternoonEnd)) {
    //     alert("For SecondHalf leave, you can Time In only between 1:00 PM to 2:30 PM.");
    //     return;
    //   } else if (leave.leave_duration === "FirstHalf" && currentTime > morningLimit) {
    //     alert("For FirstHalf leave, you cannot Time In after 10:15 AM.");
    //     return;
    //   }
    // } else if (currentTime > morningLimit) {
    //   alert("The current time is after 10:15:00. You cannot Time In.");
    //   return;
    // }

    const confirmation = window.confirm("Are you sure you want to Time In?");
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");
        const employeeCode = JSON.parse(storedUser).emp_code;
        const employeeUsername = JSON.parse(storedUser).username;
        const url = `https://blitzlearning-server.onrender.com/timein`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };
        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {        
        console.error("Error:", error);
      }
    }
  };
  // const handleTimeInClick = async () => {
  //   const confirmation = window.confirm("Are you sure you want to Time In?");
  //   if (confirmation) {
  //     try {
  //       const storedUser = localStorage.getItem("user");
  //       console.log(storedUser);

  //       const employeeCode = JSON.parse(storedUser).emp_code;
  //       console.log(employeeCode);

  //       const employeeUsername = JSON.parse(storedUser).username;
  //       console.log(employeeUsername);

  //       const url = `https://blitzlearning-server.onrender.com/timein`;

  //       const requestData = {
  //         employeeCode: employeeCode,
  //         employeeUsername: employeeUsername,
  //       };
  //       const response = await axios.post(url, requestData);
  //       console.log(response.data);
  //       window.location.reload();
  //     } catch (error) {
  //       console.error("Error:", error);
  //       // Handle errors here
  //     }
  //   } else {
  //     // Handle "No" option or do nothing
  //     // You can add your logic here for "No" option or just leave it blank if no action required
  //   }
  // };

  const handleTimeOutClick = async () => {
    const now = new Date();
    const currentTime =
      now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
    const targetTime = 18 * 3600 + 20 * 60; // 9:45:00 in seconds
    if (currentTime <= targetTime) {
      alert("The current time is before 06:20:00. You cannot Time Out.");
      return;
    }
    const confirmation = window.confirm("Are you sure you want to Time out?");
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/timeout`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleTeaBreakInOneClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Tea Break-1 time in?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/teabreakIn`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleTeaBreakOutOneClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Tea Break-1 Time Out?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/teabreakOut`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleTeaBreakInTwoClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Tea Break-2  Time In  ?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/teabreakInTwo`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleTeaBreakOutTwoClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Tea Break-2 Time Out?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/teabreakOutTwo`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleTeaBreakInThreeClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Tea Break-3 Time In ?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/teabreakInThree`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleTeaBreakOutThreeClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Tea Break-3 Time Out?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/teabreakOutThree`;

        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleSmokingBreakClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Lunch Break Out?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/smokingbreak`;

        // Prepare data to send to the backend
        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

  const handleSmokingBreakInClick = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to Lunch Break In?"
    );
    if (confirmation) {
      try {
        const storedUser = localStorage.getItem("user");

        const employeeCode = JSON.parse(storedUser).emp_code;

        const employeeUsername = JSON.parse(storedUser).username;

        const url = `https://blitzlearning-server.onrender.com/smokingbreakIn`;

        // Prepare data to send to the backend
        const requestData = {
          employeeCode: employeeCode,
          employeeUsername: employeeUsername,
        };

        const response = await axios.post(url, requestData);
        window.location.reload();
      } catch (error) {
        console.error("Error:", error);
        // Handle errors here
      }
    } else {
      // Handle "No" option or do nothing
      // You can add your logic here for "No" option or just leave it blank if no action required
    }
  };

   const currentDate = new Date();
 const currentMonth = currentDate.toLocaleString("default", { month: "short" });

 const filteredFestivals = festivalData.filter(
   (festival) => festival.id === currentMonth
 );

  return (
    <>
      <Navbar1 />
      <div className="admin-dashboard">
        <UserSidebar user={user} />
        <div className="main-content">
          <div class="row w-row">
          {filteredFestivals.length > 0 ? (
  <div className="basic-column w-col w-col-3">
    <div className="tag-wrapper">
      <div
        className="number-card number-card-content1"
        style={{ background: 'linear-gradient(to right, #ff0099, #493240)' }}
      >
        <h2 className="number-card-number2">Your Holidays</h2>
        <div className="number-card-divider1"></div>
        {filteredFestivals.map((festival) => (
          <div key={festival.name} className="number-card-dollars11">
            {festival.name} - <span className="number-card-progress11">{festival.date}</span>
          </div>
        ))}
      </div>
      <div className="divider"></div>
    </div>
  </div>
) : (
  <div className="basic-column w-col w-col-3">
  <div className="tag-wrapper">
    <div
      className="number-card number-card-content1"
      style={{ background:  'linear-gradient(to right, #02aab0, #00cdac)'}}
     
    >
      <h2 className="number-card-number2">No holiday in 
        this month
      </h2>
      <div className="number-card-divider"></div>
      <div className="number-card-dollars1"></div>
      <div className="number-card-progress-wrapper">
        <div className="tagline number-card-currency">
          <i className="fs-5 bi-calendar2"></i>
        </div>
        <div className="number-card-progress"></div>{" "}
        {/* Display the date */}
      </div>
    </div>
    <div className="divider"></div>
  </div>
</div>
)}
            <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper">
                <div class="number-card number-card-content1">
                  <h2 class="number-card-number">Total Leave</h2>

                  <div class="number-card-dollars"> {totalleave}</div>

                  {/* <div class="number-card-dollars">18</div> */}
                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper">
                    {/* <div class="tagline number-card-currency">ETH</div>
                    <div class="number-card-progress">-9.9%</div> */}
                  </div>
                </div>
                <div class="divider"></div>
                {/* <div class="style-label">.number-card .number-card-content1</div> */}
              </div>
            </div>
            {/* <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper">
                <div class="number-card number-card-content2">
                  <h2 class="number-card-number">Vacation Leave</h2>
                  {vacationLeaveData.map((item, index) => (
                    <div class="number-card-dollars"> {item.leavecount}</div>
                  ))}
                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper">
                   
                  </div>
                </div>
                <div class="divider"></div>
              </div>
            </div> */}

            <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper">
                <div class="number-card number-card-content2">
                  <h2 class="number-card-number">Leaves Taken</h2>
                  {/* {vacationLeaveData.map((item, index) => ( */}
                  <div class="number-card-dollars"> {takingLeave}</div>
                  {/* ))} */}
                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper"></div>
                </div>
                <div class="divider"></div>
              </div>
            </div>
            
            <div class="basic-column w-col w-col-3">
              <div class="tag-wrapper">
                <div class="number-card number-card-content4">
                  <h2 class="number-card-number">Balance Leave</h2>
                  <div class="number-card-dollars">{trackingData}</div>

                  <div class="number-card-divider"></div>
                  <div class="number-card-progress-wrapper">
                    {/* <div class="tagline number-card-currency">ENG</div>
                    <div class="number-card-progress">+3.2%</div> */}
                  </div>
                </div>
                <div class="divider"></div>
                {/* <div class="style-label">.NUMBER-CARD .NUMBER-CARD-CONTENT4</div> */}
              </div>
            </div>
            <div className="col-md-12 mt-0">
              <div className="user-dashboard-card-12 ">
                <h3 style={{ fontWeight: "600" }}>Timesheet</h3>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="col-md-4">
                    <Card style={{ border: "none", backgroundColor: "" }}>
                      <Card.Body>
                        <Card.Text>
                          <Card.Text>
                            {timesheetData.length === 0 ? (
                              <div>
                                <>
                                  <Button
                                    className="btn1"
                                    onClick={handleTimeInClick}
                                  >
                                    Time In
                                  </Button>
                                </>
                              </div>
                            ) : (
                              timesheetData.map((item, index) => {
                                // Function to format the date to "YYYY-MM-DD"
                                const formatDate = (dateString) => {
                                  const date = new Date(dateString);
                                  return `${date.getFullYear()}-${(
                                    date.getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}-${date
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`;
                                };

                                const formattedUserCurrentDate = formatDate(
                                  item.user_current_date
                                );

                                return (
                                  <React.Fragment key={index}>
                                    
                                    {formattedUserCurrentDate === todayDate ? (
                                      <>
                                        <Button
                                          className="btn2"
                                          onClick={handleTimeInClick}
                                          disabled // Add disabled attribute to disable the icon
                                        >
                                          Time In
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          className="btn1"
                                          onClick={handleTimeInClick}
                                        >
                                          Time In
                                        </Button>
                                      </>
                                    )}
                                  </React.Fragment>
                                );
                              })
                            )}
                          </Card.Text>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-md-3">
                    <Card className="time-line-card" style={{ border: "none" }}>
                      <Card.Body>
                        <Card.Text>
                          {timesheetData.length === 0 ? (
                            <div>
                              <>
                                <Button
                                  className="btn1"
                                  onClick={handleTimeOutClick}
                                >
                                  Time Out
                                </Button>
                              </>
                            </div>
                          ) : (
                            timesheetData.map((item, index) => {
                              // Function to format the date to "YYYY-MM-DD"
                              const formatDate = (dateString) => {
                                const date = new Date(dateString);
                                return `${date.getFullYear()}-${(
                                  date.getMonth() + 1
                                )
                                  .toString()
                                  .padStart(2, "0")}-${date
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}`;
                              };

                              const formattedUserCurrentDate = formatDate(
                                item.user_current_date
                              );

                              return (
                                <React.Fragment key={index}>
                                  
                                  {formattedUserCurrentDate === todayDate &&
                                  item.time_out != null ? (
                                    <>
                                      <Button
                                        className="btn2"
                                        onClick={handleTimeOutClick}
                                        disabled // Add disabled attribute to disable the icon
                                      >
                                        Time Out
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn1"
                                        onClick={handleTimeOutClick}
                                      >
                                        Time Out
                                      </Button>
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-md-3">
                    <Card
                      className="time-line-card"
                      style={{ border: "none", marginLeft: "150px" }}
                    >
                      <Card.Body>
                        <Card.Title>View timesheet</Card.Title>
                        <Card.Text>
                          {timesheetData.map((index) => (
                            <div key={index}>
                              <strong
                                style={{ fontWeight: "600", color: "#230B54" }}
                              >
                                Time in :
                              </strong>{" "}
                              {index.time_in}
                              <br />
                              <strong
                                style={{ fontWeight: "600", color: "#230B54" }}
                              >
                                Time out :
                              </strong>{" "}
                              {index.time_out}
                            </div>
                          ))}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>

            {/* //////////////////////////////////////////////////////////////////////////// */}
            {/* {timesheetData.some(item =>  item.time_in === '' && item.time_out === '' ) ? ( */}

            <div className="col-md-12 mt-3">
              <div className="user-dashboard-card-12 ">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3 style={{ fontWeight: "600" }}>Break Time Sheet</h3>
                  {loading ? (
                    <div className="loading-spinner">
                      <Spinner animation="border" role="status">
                        <span className="sr-only"></span>
                      </Spinner>
                    </div>
                  ) : (
                    <div style={{ fontWeight: "600", marginRight: "100px" }}>
                      {timeCount.length > 0 ? (
                        <>
                          <strong
                            style={{
                              fontWeight: "600",
                              color: "#230B54",
                            }}
                          >
                            Total Minutes
                          </strong>{" "}
                          : 60
                          <br />
                          <strong
                            style={{
                              fontWeight: "600",
                              color: "#230B54",
                            }}
                          >
                            Remaining Minutes
                          </strong>{" "}
                          : {timeCount[0].remaining_minutes}
                          <br />
                          <strong
                            style={{
                              fontWeight: "600",
                              color: "#230B54",
                            }}
                          >
                            Taking Time
                          </strong>{" "}
                          : {timeCount[0].total_minutes}:
                          {timeCount[0].total_seconds}
                        </>
                      ) : (
                        <div>
                          <>
                            <strong
                              style={{
                                fontWeight: "600",
                                color: "#230B54",
                              }}
                            >
                              Total Minutes
                            </strong>{" "}
                            : 60
                            <br />
                            <strong
                              style={{
                                fontWeight: "600",
                                color: "#230B54",
                              }}
                            >
                              Remaining Minutes
                            </strong>{" "}
                            : No breaking time.
                            <br />
                            <strong
                              style={{
                                fontWeight: "600",
                                color: "#230B54",
                              }}
                            >
                              Taking Time
                            </strong>{" "}
                            : No breaking time.
                          </>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "",
                  }}
                >
                  <div className="col-md-4">
                    <Card
                      style={{
                        border: "none",
                        backgroundColor: "",
                        height: "170px",
                      }}
                    >
                      <Card.Body>
                        <Card.Title>Tea Break</Card.Title>

                        <Card.Text>
                          {/* style={{ border: "none", backgroundColor: "yellow" }} */}
                          {/* || timesheetData[0].time_out !== '' */}
                          {timesheetData.length === 0 ? (
                            <>
                              <Button
                                className="btn-disable"
                                onClick={handleTeaBreakOutOneClick}
                              >
                                Time Start
                              </Button>
                              <PiBowlSteamFill className="tea-icon" />
                              <Button
                                className="btn-disable"
                                onClick={handleTeaBreakInOneClick}
                              >
                                Time End
                              </Button>
                            </>
                          ) : (
                            timesheetData.map((item, index) => {
                              const formatDate = (dateString) => {
                                const date = new Date(dateString);
                                return `${date.getFullYear()}-${(
                                  date.getMonth() + 1
                                )
                                  .toString()
                                  .padStart(2, "0")}-${date
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}`;
                              };

                              const formattedUserCurrentDate = formatDate(
                                item.user_current_date
                              );

                              return (
                                <React.Fragment key={index}>
                                 
                                  {formattedUserCurrentDate === todayDate &&
                                  item.tea_break === null &&
                                  item.tea_break_in === null ? (
                                    <>
                                      <Button
                                        className="btn-enable"
                                        onClick={handleTeaBreakOutOneClick}
                                      >
                                        Time Start
                                      </Button>
                                      <PiBowlSteamFill className="tea-icon" />
                                      <Button
                                        className="btn-disable"
                                        onClick={handleTeaBreakInOneClick}
                                      >
                                        Time End
                                      </Button>
                                    </>
                                  ) : item.tea_break != "" &&
                                    item.tea_break_in === null ? (
                                    <>
                                      <Button
                                        onClick={handleTeaBreakOutOneClick}
                                        className="btn-disable"
                                      >
                                        Time Start
                                      </Button>
                                      <PiBowlSteamFill className="tea-icon" />
                                      <Button
                                        onClick={handleTeaBreakInOneClick}
                                        className="btn-enable"
                                      >
                                        Time End
                                      </Button>
                                    </>
                                  ) : item.tea_break_in != "" &&
                                    item.tea_break != "" ? (
                                    <>
                                      <Button
                                        onClick={handleTeaBreakOutOneClick}
                                        className="btn-disable"
                                      >
                                        Time Start
                                      </Button>
                                      <PiBowlSteamFill className="tea-icon" />
                                      <Button
                                        onClick={handleTeaBreakInOneClick}
                                        className="btn-disable"
                                      >
                                        Time End
                                      </Button>
                                    </>
                                  ) : //    timesheetData[0].time_out !== ''? (
                                  //   <>
                                  //     <Button
                                  //       onClick={handleTeaBreakOutOneClick}
                                  //       className="btn-disable"
                                  //     >
                                  //       Time Out
                                  //     </Button>
                                  //     <PiBowlSteamFill className="tea-icon" />
                                  //     <Button
                                  //       onClick={handleTeaBreakInOneClick}
                                  //       className="btn-disable"
                                  //     >
                                  //       Time In
                                  //     </Button>
                                  //   </>
                                  // ) :
                                  null}
                                </React.Fragment>
                              );
                            })
                          )}
                          <br />

                          {timesheetData.length === 0 ? (
                            <>
                              <Button
                                onClick={handleTeaBreakOutTwoClick}
                                className="btn-disable"
                              >
                                Time Start
                              </Button>
                              <PiBowlSteamFill className="tea-icon" />
                              <Button
                                onClick={handleTeaBreakInTwoClick}
                                className="btn-disable"
                              >
                                Time End
                              </Button>
                            </>
                          ) : (
                            timesheetData.map((item, index) => {
                              const formatDate = (dateString) => {
                                const date = new Date(dateString);
                                return `${date.getFullYear()}-${(
                                  date.getMonth() + 1
                                )
                                  .toString()
                                  .padStart(2, "0")}-${date
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}`;
                              };

                              const formattedUserCurrentDate = formatDate(
                                item.user_current_date
                              );

                              return (
                                <React.Fragment key={index}>
                                 
                                  {formattedUserCurrentDate === todayDate &&
                                  item.tea_break_two === null &&
                                  item.tea_break_two_in === null ? (
                                    <>
                                      <Button
                                        onClick={handleTeaBreakOutTwoClick}
                                        className="btn-enable"
                                      >
                                        Time Start
                                      </Button>
                                      <PiBowlSteamFill className="tea-icon" />
                                      <Button
                                        onClick={handleTeaBreakInTwoClick}
                                        className="btn-disable"
                                      >
                                        Time End
                                      </Button>
                                    </>
                                  ) : item.tea_break_two != "" &&
                                    item.tea_break_two_in === null ? (
                                    <>
                                      <Button
                                        onClick={handleTeaBreakOutTwoClick}
                                        className="btn-disable"
                                      >
                                        Time Start
                                      </Button>
                                      <PiBowlSteamFill className="tea-icon" />
                                      <Button
                                        onClick={handleTeaBreakInTwoClick}
                                        className="btn-enable"
                                      >
                                        Time End
                                      </Button>
                                    </>
                                  ) : item.tea_break_two_in != "" &&
                                    item.tea_break_two != "" ? (
                                    <>
                                      <Button
                                        onClick={handleTeaBreakOutTwoClick}
                                        className="btn-disable"
                                      >
                                        Time Start
                                      </Button>
                                      <PiBowlSteamFill className="tea-icon" />
                                      <Button
                                        onClick={handleTeaBreakInTwoClick}
                                        className="btn-disable"
                                      >
                                        Time End
                                      </Button>
                                    </>
                                  ) : null}
                                </React.Fragment>
                              );
                            })
                          )}
                          
                          
                               
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-md-3">
                    <Card className="time-line-card" style={{ border: "none" }}>
                      <Card.Body>
                        <Card.Title>Lunch Break</Card.Title>
                        <Card.Text>
                          {timesheetData.length === 0 ? (
                            <div>
                              <Button
                                onClick={handleSmokingBreakClick}
                                className="btn-Lunch-disable"
                              >
                                Lunch Start
                              </Button>
                              <IoFastFoodOutline className="Lunch-icon" />
                              <Button
                                onClick={handleSmokingBreakInClick}
                                className="btn-Lunch-disable"
                              >
                                Lunch End
                              </Button>
                            </div>
                          ) : (
                            timesheetData.map((item, index) => {
                              const formatDate = (dateString) => {
                                const date = new Date(dateString);
                                return `${date.getFullYear()}-${(
                                  date.getMonth() + 1
                                )
                                  .toString()
                                  .padStart(2, "0")}-${date
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0")}`;
                              };

                              const formattedUserCurrentDate = formatDate(
                                item.user_current_date
                              );

                              return (
                                <React.Fragment key={index}>
                                  
                                  {formattedUserCurrentDate === todayDate &&
                                  item.smoking_break === null &&
                                  item.smoking_break_in === null ? (
                                    <>
                                      <Button
                                        onClick={handleSmokingBreakClick}
                                        className="btn-Lunch-enable"
                                      >
                                        Lunch Start
                                      </Button>
                                      <IoFastFoodOutline className="Lunch-icon" />
                                      <Button
                                        onClick={handleSmokingBreakInClick}
                                        className="btn-Lunch-disable"
                                      >
                                        Lunch End
                                      </Button>
                                    </>
                                  ) : item.smoking_break != "" &&
                                    item.smoking_break_in === null ? (
                                    <>
                                      <Button
                                        onClick={handleSmokingBreakClick}
                                        className="btn-Lunch-disable"
                                      >
                                        Lunch Start
                                      </Button>
                                      <IoFastFoodOutline className="Lunch-icon" />
                                      <Button
                                        onClick={handleSmokingBreakInClick}
                                        className="btn-Lunch-enable"
                                      >
                                        Lunch End
                                      </Button>
                                    </>
                                  ) : item.smoking_break_in != "" &&
                                    item.smoking_break != "" ? (
                                    <>
                                      <Button
                                        onClick={handleSmokingBreakClick}
                                        className="btn-Lunch-disable"
                                      >
                                        Lunch Start
                                      </Button>
                                      <IoFastFoodOutline className="Lunch-icon" />
                                      <Button
                                        onClick={handleSmokingBreakInClick}
                                        className="btn-Lunch-disable"
                                      >
                                        Lunch End
                                      </Button>
                                    </>
                                  ) : null}
                                </React.Fragment>
                              );
                            })
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="col-md-3">
                    <Card
                      className="time-line-card"
                      style={{ border: "none", marginLeft: "150px" }}
                    >
                      <Card.Body>
                        <Card.Title>View timesheet</Card.Title>
                        <Card.Text>
                          {timesheetData.map((index) => (
                            <div key={index}>
                              {index.tea_break && index.tea_break_in ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Tea Break-1 :
                                  </strong>{" "}
                                  {index.tea_break} to {index.tea_break_in}
                                </>
                              ) : index.tea_break ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Tea Break-1 :
                                  </strong>{" "}
                                  {index.tea_break}
                                </>
                              ) : index.tea_break_in ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Tea Break-1 :
                                  </strong>{" "}
                                  to {index.tea_break_in}
                                </>
                              ) : null}

                              <br />
                              {index.tea_break_two && index.tea_break_two_in ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Tea Break-2 :
                                  </strong>{" "}
                                  {index.tea_break_two} to{" "}
                                  {index.tea_break_two_in}
                                </>
                              ) : index.tea_break_two ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Tea Break-2 :
                                  </strong>{" "}
                                  {index.tea_break_two}
                                </>
                              ) : index.tea_break_two_in ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Tea Break-2 :
                                  </strong>{" "}
                                  to {index.tea_break_two_in}
                                </>
                              ) : null}
                             
                              <br />
                              {index.smoking_break && index.smoking_break_in ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Lunch Break :
                                  </strong>{" "}
                                  {index.smoking_break} to{" "}
                                  {index.smoking_break_in}
                                </>
                              ) : index.smoking_break ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Lunch Break :
                                  </strong>{" "}
                                  {index.smoking_break}
                                </>
                              ) : index.smoking_break_in ? (
                                <>
                                  <strong
                                    style={{
                                      fontWeight: "600",
                                      color: "#230B54",
                                    }}
                                  >
                                    Lunch Break :
                                  </strong>{" "}
                                  to {index.smoking_break_in}
                                </>
                              ) : null}
                            </div>
                          ))}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDashboard;
