import React, { useState, useEffect } from "react";
import { Button, Table, Pagination, Container, Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar1 from "./Navbar1";
import UserSidebar from "./UserSidebar";

function UserTimein() {
 
  const [email, setEmail] = useState(""); // New state for email
 
  const location = useLocation();
  const navigate = useNavigate();
  
  const [users, setUser] = useState(null);
  const [empCode, setEmpCode] = useState();
 
  const [depart, setDepart] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setEmpCode(parsedUser.emp_code);
      setEmail(parsedUser.email); // Set email from stored user
      setDepart(parsedUser.department);
    }

    const employeeCode = JSON.parse(storedUser).emp_code;
    if (employeeCode) {
      //   fetchLeaveData(employeeCode);
      fetchUserProfiles(employeeCode);
    }
    // fetchUserProfiles();
  }, []);

  const fetchUserProfiles = async (employeeCode) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://blitzlearning-server.onrender.com/usertimeinDetails/${employeeCode}`);
      const sortedData = response.data.sort((a, b) => {
        const dateComparison = new Date(b.user_current_date) - new Date(a.user_current_date);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return a.name.localeCompare(b.name);
      });
      setUserProfiles(sortedData);
    } catch (error) {
      console.error("Error fetching user profiles:", error);
    }
    setIsLoading(false);
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/\//g, "-");
  };

  const compareTime = (time1, time2) => {
    const [hours1, minutes1, seconds1] = time1.split(":").map(Number);
    const [hours2, minutes2, seconds2] = time2.split(":").map(Number);
    return (
      new Date(0, 0, 0, hours1, minutes1, seconds1) >
      new Date(0, 0, 0, hours2, minutes2, seconds2)
    );
  };

  // Get current profiles
  const indexOfLastProfile = currentPage * itemsPerPage;
  const indexOfFirstProfile = indexOfLastProfile - itemsPerPage;
  const currentProfiles = userProfiles.slice(indexOfFirstProfile, indexOfLastProfile);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userProfiles.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Navbar1 />
      <div style={{ display: "flex", backgroundColor: "#aecfdb" }}>
        <UserSidebar user={users} />
        <div className="leave-applications">
          <div className="p-0 d-flex justify-content-center shadow">
            <h3>Timein Details</h3>
          </div>
          <Container className="px-3 mt-3">
            <div className="mt-3">
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only"></span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Table striped bordered hover responsive>
                    <thead className="text-center">
                      <tr>
                        <th>Emp Code</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Tea Break-1</th>
                        <th>Lunch Break</th>
                        <th>Total Break Time</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {currentProfiles.map((profile, index) => (
                        <tr key={`${profile.emp_code}-${index}`}>
                          <td>{profile.emp_code}</td>
                          <td>{profile.name}</td>
                          <td>{formatDate(profile.user_current_date)}</td>
                          <td>
                            {profile.time_in ? (
                              <span
                                style={{
                                  background: compareTime(profile.time_in, "10:15:00") ? "#9A1B15" : "#52c41a",
                                  color: "white",
                                  padding: "3px",
                                  borderRadius: "3px",
                                }}
                              >
                                {profile.time_in}
                              </span>
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {profile.time_out ? (
                              <span
                                style={{
                                  background: compareTime(profile.time_out, "18:27:00") ? "#52c41a" : "#9A1B15",
                                  color: "white",
                                  padding: "3px",
                                  borderRadius: "3px",
                                }}
                              >
                                {profile.time_out}
                              </span>
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {profile.tea_break && profile.tea_break_in ? (
                              <>
                                {profile.tea_break} to {profile.tea_break_in}
                              </>
                            ) : profile.tea_break ? (
                              <>{profile.tea_break}</>
                            ) : profile.tea_break_in ? (
                              <>to {profile.tea_break_in}</>
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          
                         
                          <td>
                            {profile.smoking_break && profile.smoking_break_in ? (
                              <>
                                {profile.smoking_break} to {profile.smoking_break_in}
                              </>
                            ) : profile.smoking_break ? (
                              <>{profile.smoking_break}</>
                            ) : profile.smoking_break_in ? (
                              <>to {profile.smoking_break_in}</>
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                          <td>
                            {profile.totalMinutes ? (
                              <>{profile.totalMinutes}</>
                            ) : (
                              <span>--</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination>
                    {pageNumbers.map((number) => (
                      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                        {number}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default UserTimein;
