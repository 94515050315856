import React, { useState, useEffect } from 'react';
import Navbar1 from './Navbar1';
import Sidebar from './Sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, FormControl, Button,Alert } from 'react-bootstrap';
import '../Hrms/newusersignup.css';

const AddUserForm = () => {
    const navigate = useNavigate();
    const [picture, setPicture] = useState(null); 
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        emp_code: '',
        username: '',
        password: '',
        confirmpassword: '',
        dob:'',
        name: '',
        dateofjoining: '',
        designation: '',
        department: ''

    });

    const [submitted, setSubmitted] = useState(false); // State variable for form submission message
 

    const handleChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value
            
        }));

 // Clear the error message when the user starts typing again
 setErrors((prevErrors) => ({
    ...prevErrors,
    [e.target.name]: ''
}));

    };
    const handlePictureChange = (e) => {
        setFile(e.target.files[0]); // Grab the first file from the selected files array
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!file) {
                alert('Please upload an image');
                return;
            }
            const formDataToSend = new FormData();
            formDataToSend.append('image', file);
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });
            await axios.post('https://blitzlearning-server.onrender.com/newuserdata', formDataToSend);
            setFormData({
                emp_code: '',
                username: '',
                password: '',
                confirmpassword: '',
                dob: '',
                name: '',
                dateofjoining: '',
                designation: '',
                department: ''
            });
            setFile(null);
            setSubmitted(true);
            alert("Employee details saved successfully")
        } catch (error) {
            console.error('Error saving employee details:', error);
        }
    };
    const [errors, setErrors] = useState({}); // State variable for form errors
    // const validateForm = () => {
    //     let valid = true;
    //     const newErrors = {};

    // // Validate emp_code
    // if (!/^Blitz-\d{3}$/.test(formData.emp_code)) {
    //     newErrors.emp_code = 'Employee code must be in the format Blitz-XXX, where XXX is a three-digit number';
    //     valid = false;
    // }

    //    // Validate username
    //    if (!/^[a-zA-Z]+@\d+$/.test(formData.username)) {
    //     newErrors.username = 'Username must be your first name followed by "@" symbol and then digits';
    //     valid = false;
    // }

    //  // Validate password
    //  if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,10}/.test(formData.password)) {
    //     newErrors.password = 'Password must be 6-10 characters long, containing at least one uppercase letter, one lowercase letter, one special symbol.';
    //     valid = false;
    // }

    // // Validate confirm password
    // if (formData.password !== formData.confirmpassword) {
    //     newErrors.confirmpassword = 'Password and confirm password do not match';
    //     valid = false;
    // }




    //        // Validate designation
    // if (!/^[a-zA-Z]{4,12}$/.test(formData.designation)) {
    //     newErrors.designation = 'Designation must contain 4 to 12 alphabets only';
    //     valid = false;
    // }

    // // Validate department
    // if (!formData.department) {
    //     newErrors.department = 'Please select a department';
    //     valid = false;
    // }
    
    //     setErrors(newErrors);
    //     return valid;
    // };

    useEffect(() => {
        // Reset the submitted state after 3 seconds
        if (submitted) {
            const timeout = setTimeout(() => {
                setSubmitted(false);
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [submitted]);

    const [adminName, setAdminName] = useState('');

    useEffect(() => {
        fetchAdminName();
    }, []);

    const fetchAdminName = async () => {
        try {
            const response = await axios.get('https://blitzlearning-server.onrender.com/admin/profile');
            setAdminName(response.data.name);
        } catch (error) {
            console.error('Error fetching admin name:', error);
        }
    };

    return (
        <>
            <Navbar1 />
            <div style={{ display: 'flex', backgroundColor: '#aecfdb' }}>
                <Sidebar adminName={adminName} />
                <div style={{ width: '100%', maxWidth: '600px', margin: '20px auto', border: '2px solid black',height:'auto' }}>
                <h3 className='text-center' >Add User Form </h3>
                {submitted && (
                                <Alert variant="success" className="mt-3">
                                    Form submitted successfully!
                                </Alert>
                            )}

                    <Form onSubmit={handleSubmit}>
            
                        <div style={{ padding: '20px' }}>
                  
                            <FormGroup controlId="emp_code">
                                <Form.Label>Employee Code:</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder="Enter employee code"
                                    value={formData.emp_code}
                                    onChange={handleChange}
                                    required
                                    name="emp_code"
                                />
                             {/* {errors.emp_code && <p className="text-danger">{errors.emp_code}</p>} */}
                            </FormGroup>

                            <FormGroup controlId="username">
                                <Form.Label>Username:</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder="Enter username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                    name="username"
                                />
                               {/* {errors.username && <p className="text-danger">{errors.username}</p>} */}
                            </FormGroup>

                            <FormGroup controlId="picture">
                                <Form.Label>Upload Picture:</Form.Label>
                                <FormControl
                                    type="file"
                                    accept="image/*"
                                    onChange={handlePictureChange}
                                    name="picture"
                                />
                            </FormGroup>
                            <FormGroup controlId="password">
                                <Form.Label>Password:</Form.Label>
                                <FormControl
                                    type="password"
                                    placeholder="Enter password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    name="password"
                                />
                                 {/* {errors.password && <p className="text-danger">{errors.password}</p>} */}
                         
                            </FormGroup>
                            <FormGroup controlId="confirmPassword">
                                <Form.Label>Confirm Password:</Form.Label>
                                <FormControl
                                    type="password"
                                    placeholder="Confirm password"
                                    value={formData.confirmpassword}
                                    onChange={handleChange}
                                    required
                                    name="confirmpassword"
                                />
                                {/* {errors.confirmpassword && <p className="text-danger">{errors.confirmpassword}</p>} */}
                            </FormGroup>

                            <FormGroup controlId="dob">
                                <Form.Label>Date of Birth:</Form.Label>
                                <FormControl
                                    type="date"
                                    placeholder="Date of Birth"
                                    value={formData.dob}
                                    onChange={handleChange}
                                    required
                                    name="dob"
                                />
                               
                            </FormGroup>

                            <FormGroup controlId="name">
                                <Form.Label>Name:</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder="Enter name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    name="name"
                                />
                                  {/* {errors.name && <p className="text-danger">{errors.name}</p>} */}
                            </FormGroup>

                            <FormGroup controlId="dateofjoining">
                                <Form.Label>Date of Joining:</Form.Label>
                                <FormControl
                                    type="date"
                                    value={formData.dateofjoining}
                                    onChange={handleChange}
                                    required
                                    name="dateofjoining"
                                />
                            </FormGroup>
                            <FormGroup controlId="designation">
                                <Form.Label>Designation:</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder="Enter designation"
                                    value={formData.designation}
                                    onChange={handleChange}
                                    required
                                    name="designation"
                                />
                                {/* {errors.designation && <p className="text-danger">{errors.designation}</p>} */}
                            </FormGroup>
                            <FormGroup controlId="department">
                                <Form.Label>Department:</Form.Label>
                                <FormControl
                                    as="select"
                                    value={formData.department}
                                    onChange={handleChange}
                                    required
                                    name="department"
                                >
                                    <option value="" disabled>Select Department</option>
                                    <option value="Sales">Sales</option>
                                    <option value="Production">Production</option>
                                    <option value="Support">Support</option>
                                    <option value="Accounts">Accounts</option>
                                    <option value="IT">IT</option>
                                    <option value="Operation">Operation</option>
                                </FormControl>
                            </FormGroup>

                            <Button variant="primary" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#E55A1B',border:'none' }} type="submit" className="btn btn-primary mt-4 mx-4">
                                Submit
                            </Button>
                            

                        </div>
                    </Form>


                </div>
            </div>
        </>
    );
};

export default AddUserForm;
