import axios from 'axios';
import React, { useState } from 'react';
import { Image } from "react-bootstrap";
import Header from './Header';
import homelogo from "../Images/Picture4.png";

function EmployeeDetail() {
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        emp_code:'',
        username: '',
        password: '',
        confirmPassword: '',
        dob:'',
        name: '',
        dateOfJoining: '',
        designation: '',
        department: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const selectedHandler = e => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!file) {
                alert('Please upload an image');
                return;
            }
    
            const formDataToSend = new FormData();
            formDataToSend.append('image', file);
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });
            const response = await axios.post('https://blitzlearning-server.onrender.com/upload-and-save-employee', formDataToSend);

            // Show alert message
            showAlert();

            // Clear form data and file state after successful submission
            setFormData({
                emp_code:'',
                username: '',
                password: '',
                confirmPassword: '',
                dob:'',
                name: '',
                dateOfJoining: '',
                designation: '',
                department: ''
            });
            setFile(null);
        } catch (error) {
            console.error('Error saving employee details:', error);
            // Handle error
        }
    };

    const showAlert = () => {
        window.alert('Form submitted successfully');

        // Close the alert after 3 seconds
        setTimeout(() => {
            const alertBox = document.querySelector('.alert');
            if (alertBox) {
                alertBox.style.display = 'none';
            }
            // Redirect to previous page
            window.history.back();
        }, 3000);
    };

    return (
        <div className='employee-dashboard'>
            <Header />
            <div className="employee-login-01" style={{height:'auto',marginBottom:'20px'}} >
                <Image style={{height:'80px',width:'150px'}} className="logo" src={homelogo} alt="Logo" />
                <div style={{marginLeft:'85px'}} >
                    <form className='form-container' onSubmit={handleSubmit}>
                        <input className='form-input' type="text" placeholder="Employee code" required name="emp_code" value={formData.emp_code} onChange={handleChange}   style={{borderRadius:'10px',border:'none'}}/>
                        <input className='form-input' type="file" placeholder="Upload picture" required name="upl_pic" onChange={selectedHandler}   style={{borderRadius:'10px',border:'none'}}/>
                        <input className='form-input' type="text" placeholder="Username" required name="username" value={formData.username} onChange={handleChange}   style={{borderRadius:'10px',border:'none'}}/>
                        <input className='form-input' type="password" placeholder="Password" required name="password" value={formData.password} onChange={handleChange}  style={{borderRadius:'10px',border:'none'}} />
                        <input className='form-input' type="password" placeholder="Confirm Password" required name="confirmPassword" value={formData.confirmPassword} onChange={handleChange}  style={{borderRadius:'10px',border:'none'}} />
                        <input className='form-input' type="date" placeholder="Date of Birth" required name="dob" value={formData.dob} onChange={handleChange}  style={{borderRadius:'10px',border:'none'}} />
                        <input className='form-input' type="text" placeholder="Name" required name="name" value={formData.name} onChange={handleChange}  style={{borderRadius:'10px',border:'none'}} />
                        <input className='form-input' type="date" placeholder="Date of joining" required name="dateOfJoining" value={formData.dateOfJoining} onChange={handleChange}  style={{borderRadius:'10px',border:'none'}} />
                        <input className='form-input' type="text" placeholder="Designation" required name="designation" value={formData.designation} onChange={handleChange}   style={{borderRadius:'10px',border:'none'}}/>
                        <div>
                            <select className='form-input' required name="department" value={formData.department} onChange={handleChange}  style={{borderRadius:'10px',border:'none'}}>
                                <option value="" disabled>Select Department</option>
                                <option value="Sales">Sales</option>
                                <option value="Production">Production</option>
                                <option value="Support">Support</option>
                                <option value="Accounts">Accounts</option>
                                <option value="IT">IT</option>
                                <option value="Operation">Operation</option>
                            </select>
                        </div>
                        <button className="signup-btn" type="submit">Sign up</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmployeeDetail;
